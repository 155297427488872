@import './theme.scss';
@import "/node_modules/angular-calendar/css/angular-calendar.css";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

:root {
	--main-color: #002933;
	--main-color-rgb: 0, 41, 51;
	--main-color-light: #284951;
	--text-color: white;
	--accent-color: #ff586b;
	--grey-hover-color: #e2e8ec;
	--button-color: #005693;
	--tab-active-color: #6CC76C;
	--gray-200: #E5E7EB;
	--danger-color: #e88787;
	--success-color: #7ae3b5;
	--mdc-checkbox-unselected-pressed-icon-color: #1890ff;
	--mdc-checkbox-unselected-hover-icon-color: #1890ff;
	--mdc-checkbox-selected-pressed-icon-color: #1890ff;
	--mdc-checkbox-selected-hover-icon-color: #1890ff;
	--mdc-checkbox-selected-icon-color: #1890ff;
	--mdc-checkbox-selected-focus-icon-color: #1890ff;
	--mat-option-selected-state-label-text-color: #1890ff;
	--su-color-primary: #002933f5;
}

* {
	font-family: "Muli", sans-serif !important;
}

html,
body {
	margin: 0 !important;
	padding: 0 !important;
	font-family: "Inter", sans-serif !important;
	height: 100%;
	min-height: 100%;
	overflow: auto;
	background: #F7F7F8;
	--mdc-outlined-text-field-caret-color: black;
	left: auto !important;
	top: auto !important;
}

a {
	text-decoration: none;
}

.ng-scrollbar-wrapper {
	--scrollbar-total-size: 0px
}

.filter-option>.mat-pseudo-checkbox {
	display: none !important;
}

.font-inter {
	font-family: "Ubuntu";
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: #000;
}

.font-weight-600 {
	font-weight: 550 !important;
}

.label-font {
	font-family: Ubuntu;
	font-size: 0.99rem;
	// font-weight: 500;
	line-height: 24px;
	text-align: left;

}

.clear-button {
	background: transparent !important;
	border: 0px !important;
}

.material-icons {
	font-family: "Material Icons" !important;
}

h1 {
	font-size: 1.8rem !important;
}

h2 {
	font-size: 1.5rem !important;
}

.p-8 {
	padding: 8px !important;
}

.pr-8 {
	padding-right: 8px !important;
}

.pb-8 {
	padding-bottom: 8px !important;
}

// add custom css on calendar columns
.cal-month-view .bg-gray,
.cal-week-view .cal-day-columns .bg-gray,
.cal-day-view .bg-gray {
	background-color: #e2e2e2 !important;
}

.continue-button {
	background: #2083E3;
	border: #2083E3;
	border-radius: 2px;
	height: 47px;
	font-size: larger;
	width: 49%;
}

.save-button {
	position: fixed;
	bottom: 15px;
	right: 120px;
	left: 0;
	z-index: 999;
}

.continue-button:hover {
	background: #2083E3 !important;
	border-color: #2083E3 !important;
	color: white;
}

.previous-button {
	color: rgb(125, 132, 145);
	border-radius: 2px;
	font-weight: 500;
	font-size: 17px;
	width: 50%;
	height: 47px;
	border: 2px solid var(--gray-200, #E5E7EB);
}

.previous-button:hover {
	border-color: #5b5e64;
	color: #5b5e64;

}


.table-search-input mat-form-field .mat-mdc-form-field-icon-prefix>.mat-icon {
	padding-top: 7px !important;
}

.mat-mdc-form-field-icon-suffix>.mat-icon {
	padding: 10px !important;
}

.table-search-input mat-form-field .mat-mdc-form-field-infix {
	padding-top: 8.5px !important;
	padding-bottom: 11px !important;
}

.table-search-input .text-form-field-margin {
	padding-top: 0px !important;
}

::-webkit-scrollbar {
	height: 4.65px !important;
	width: 4.65px !important;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #D1D5DB;
	border-radius: 8px;
}

.save-btn {
	min-width: 150px;
}

.mat-mdc-unelevated-button:not(:disabled) {
	background: #52AAFE !important;
	border-color: #52aafe !important;
	color: white !important;
}

.mat-mdc-form-field-subscript-wrapper {
	position: relative !important;
}

.mdc-data-table__header-cell {
	font-size: 15px;
	font-weight: 500;
	line-height: 18px;
	color: #6B7280;
	background-color: white;
	text-transform: capitalize !important;
}

.mdc-data-table__cell {
	border-bottom: 1px solid #f0f0f0;
	font-size: 14px;
	font-weight: 500;
	line-height: 22.5px;
	text-align: left;
	white-space: nowrap;
	border-radius: 1px solid;
	color: #6B7280
}

.table-header {
	background-color: #d8e0e5 !important;
	color: white !important;
}

.mdc-button:disabled {
	color: #00000040 !important;
	border-color: #d9d9d9 !important;
	background: #f5f5f5 !important;
	text-shadow: none;
	box-shadow: none;
	cursor: not-allowed !important;
}

.mat-mdc-tab-body-wrapper {
	box-shadow: 0px 4px 6px 0px #0000000D;
	box-shadow: 0px 10px 15px -3px #0000001A;
	// box-shadow: 0px 1px 3px 0px #0000001A;
	// border: 1px solid #E5E7EB;
}

.crud-btns {
	border: none;
	background: transparent;
}

.snackbar-success {
	--mdc-snackbar-container-color: var(--success-color);
	--mat-snack-bar-button-color: black;
	--mdc-snackbar-supporting-text-color: #fff;
}

.snackbar-warning {
	--mdc-snackbar-container-color: lightcoral;
	--mat-snack-bar-button-color: black;
	--mdc-snackbar-supporting-text-color: #2d2d2d;
}

.snackbar-info {
	--mdc-snackbar-container-color: #333;
	--mat-snack-bar-button-color: black;
	--mdc-snackbar-supporting-text-color: #f5f5f5;
}

.snackbar-error {
	--mdc-snackbar-container-color: var(--danger-color);
	--mat-snack-bar-button-color: black;
	--mdc-snackbar-supporting-text-color: white;
}

.btn-danger {
	color: #ff4d4f !important;
	border-color: #ff4d4f !important;
	background: #fff !important;
	border-radius: 50px !important;
	min-width: 36px !important;
	max-width: 36px !important;
}

.btn-edit {
	color: #fff !important;
	background: #000 !important;
	border-radius: 50px !important;
	min-width: 36px !important;
	max-width: 36px !important;
}

.terms .mat-mdc-tab-body-wrapper {
	box-shadow: 0px 4px 6px 0px #0000000D;
	box-shadow: 0px 10px 15px -3px #0000001A;
	border-left: 1px solid #E5E7EB;
	border-right: 1px solid #E5E7EB;
	border-top: 1px solid #E5E7EB;
	border-bottom: none !important;
}

.login-form {
	width: 400px;
	min-width: 400px;
	max-width: 400px;
}

.events-logo {
	object-fit: contain;
	max-width: 300px !important;
	max-height: 50px !important;
}

.mat-mdc-menu-panel {
	min-width: 181px !important;
	border-radius: 2px !important;
	border: 1px solid #E5E7EB;
	margin-top: .25rem;
	box-shadow: 0px 10px 15px -3px #0000001A !important;


}

.calendar-agenda-view.mat-mdc-menu-panel {
	min-width: 650px !important;
	box-shadow: 0px 10px 15px -3px #0000001A !important;
	border-radius: 5px !important;
}

.profile.mat-mdc-menu-panel {
	min-width: 181px !important;
	border-radius: 2px !important;
	border: 1px solid #E5E7EB;
	margin-top: .25rem;
	box-shadow: 0px 10px 15px -3px #0000001A !important;
}

.calendar-header-view {
	position: sticky !important;
	top: 60px !important;
	z-index: 99 !important;
	background: #FFFFFF !important;

}

.calendar-header-view .btn-group .btn-calendar {
	border: 1px solid #CDCDCD;
	background-color: #FFFFFFCC !important;
}

.calendar-header-view .btn-group .btn-calendar.active {
	border: 1px solid #CDCDCD;
	background-color: #E7EAED !important;
}

.mat-mdc-dialog-title {
	border-bottom: 1px solid var(--gray-200);
}

.seat-type-form {
	mat-dialog-container .form-items-container {
		padding: 10px !important;
	}
}

.filter-orders {
	mat-dialog-container {
		min-width: 650px;
		max-width: 650px;
	}
}

.seat-template-form {
	mat-dialog-container .mdc-dialog__surface {
		overflow: hidden !important;
	}
}

.event-form-seat-template {
	mat-dialog-container .mdc-dialog__surface {
		overflow: hidden !important;
	}
}

.event-detail-modal {
	mat-dialog-container {
		min-width: 700px !important;
	}
}

// .dialog-title {
//     position: relative;
// }

.close-dialog {
	position: absolute !important;
	top: 0;
	right: 0;
	padding: 5px;
	line-height: 14px;
	min-width: auto;
}

.settings-dialog .mat-mdc-dialog-container {
	border-radius: 1rem !important;
}

.text-form-field-margin {
	padding-top: 11px;
}

.login-form-forgot {
	float: right;
	text-decoration: none !important;
}

.login-form-button {
	width: 100%;
	padding: 10px 0px 30px 0px;
	background: #0C3C67 !important;
}

.content-wrapper {
	padding: 50px;
}

.min-max-screen-height {
	height: 70vh;
	min-height: 70vh;
	max-height: 70vh;
	overflow: auto;
}

.evo-calendar {
	box-shadow: none !important;
}

.royal-navy .calendar-sidebar>.month-list>.calendar-months>li {
    padding: 6px 30px !important;
}

.calendar-inner {
	padding: 20 10px !important;
}

.event-indicator {
    position: static !important;
}

.royal-navy .calendar-events {    
    background-color: #282e32 !important;
}

.royal-navy .calendar-sidebar {
    background-color: #282e32 !important;
    box-shadow: none !important;
}

.royal-navy #eventListToggler {
    background-color: #52acff !important;
    box-shadow: 0 5px 10px -3px #52acff !important;
}

.royal-navy .calendar-sidebar>span#sidebarToggler {
	background-color: #52acff !important;
    box-shadow: 0 5px 10px -3px #52acff !important;
}

.height-60 {
	height: 60px !important;
}

.height-70 {
	height: 70px !important;
}

.height-80 {
	height: 80px !important;
}

.height-90 {
	height: 90px !important;
}

.height-100 {
	height: 100px !important;
}

.min-1200 {
	min-width: 1200px !important;
}

.min-1300 {
	min-width: 1300px !important;
}

.min-1400 {
	min-width: 1400px !important;
}

.max-600 {
	max-width: 600px !important;
	margin: auto !important;
}

.max-700 {
	max-width: 700px !important;
	margin: auto !important;
}

.max-800 {
	max-width: 800px !important;
	margin: auto !important;
}

.max-900 {
	max-width: 900px !important;
	margin: auto !important;
}

.max-1000 {
	max-width: 1000px !important;
	margin: auto !important;
}

.max-1100 {
	max-width: 1100px !important;
	margin: auto !important;
}

.max-1200 {
	max-width: 1200px !important;
	margin: auto !important;
}

.max-1300 {
	max-width: 1300px !important;
	margin: auto !important;
}

.max-1400 {
	max-width: 1400px !important;
	margin: auto !important;
}

.pending-color {
	background: #FFE692;
}

.online-color {
	background: #13c2c2;
}

.offline-color {
	background: #d46f71;
}

.offer-color {
	background: #3f6ad8;
}

.cal-event-title {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
}

.checked-in-color {
	background: #52c41a;
}

.checked-out-color {
	background: #faad14;
}

.cancelled-color {
	background: red !important;
}

.occupied-color {
	background: #ffc402 !important;
}

.ended-color {
	background: #FF8282 !important;
}

.started-color {
	background: #B5FFA2 !important;
}

.pending-color {
	background: #ffc402 !important;
}

.offline-color {
	background: #767676 !important;
}

.apexcharts-legend-text {
	// 
	color: #6B7280 !important;
}

.apexcharts-legend {
	column-gap: .5rem !important;
	align-items: center !important;
}

.apexcharts-legend-marker[rel="1"] {
	border-radius: 100% !important;
}

.apexcharts-legend-marker[rel="2"] {
	border-radius: 100% !important;
}

.apexcharts-pie-label {
	text-shadow: none !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #fff !important;
}

// apextree style
.tree-content {
	display: flex;
	column-gap: 10px;
	align-items: center;
}

.tree-content-title {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.tree-content-value {
	font-size: 12px;
	font-weight: 400;
	color: #5a5858;
}

#apexTreeWrapper svg {
	height: 100% !important;
}

// .apexcharts-gridline:nth-child(2) {
// 	display: none !important;
// }

.apexcharts-xaxis-tick {
	display: none !important;
}

.hint-due {
	display: block;
	width: 0;
	height: 0;
	border-top: 15px solid #ffc402;
	border-left: 15px solid transparent;
	position: absolute !important;
	top: 0;
	right: 0;
}

.hint-paid {
	display: block;
	width: 0;
	height: 0;
	border-top: 15px solid #2cd800;
	border-left: 15px solid transparent;
	position: absolute !important;
	top: 0;
	right: 0;
}

.hint-group {
	display: block;
	width: 0;
	height: 0;
	border-top: 15px solid #0070a0;
	border-right: 15px solid transparent;
	position: absolute !important;
	top: 0;
	left: 0;
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


.box-radius {
	border-radius: 8px;
}

.box-shadow {
	box-shadow: 0px 1px 2px -1px #0000001A;
	box-shadow: 0px 1px 3px 0px #0000001A;

}

.card-setting {
	border: 1.5px solid #E5E7EB;
	box-shadow: 0px 4px 6px 0px #0000000D;
	border-radius: 2px;
	padding: 5px;

}

.min-inner-height {
	min-height: calc(100vh - 154px);
}

.bg-cover {
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
}

.bg-contain {
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: contain !important;
}

.bg-color-box {
	display: inline-block;
	width: 7px;
	height: 7px;
}

.pointer {
	cursor: pointer !important;
}

.bold {
	font-weight: bold !important;
}

.img-fluid {
	width: 100% !important;
	height: auto !important;
}

.bg-white {
	background: #ffffff !important;
}

.text-green {
	color: rgb(134, 223, 0) !important;
}

.text-gold {
	color: #a39162 !important;
}

.text-gray {
	color: #a3a0a0 !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.font-10 {
	font-size: 10px !important;
}

.font-11 {
	font-size: 11px !important;
}

.font-12 {
	font-size: 12px !important;
}

.font-13 {
	font-size: 13px !important;
}

.font-14 {
	font-size: 14px !important;
}

.font-15 {
	font-size: 15px !important;
}

.font-16 {
	font-size: 16px !important;
}

.font-20 {
	font-size: 20px !important;
}

.font-24 {
	font-size: 24px !important;
}

.font-40 {
	font-size: 40px !important;
}

.credit-card-img {
	max-width: 200px;
	height: auto;
}

.custom-loader {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.3);
}

.ant-table-filter-trigger {
	font-size: 16px;
}

.search-box {
	padding: 8px;
}

.search-input {
	.mat-mdc-form-field-infix {
		min-height: 5px;
	}


}

.search-box input {
	width: 188px;
	margin-bottom: 8px;
	display: block;
}

.search-box button {
	width: 90px;
}

.search-button {
	margin-right: 8px;
}

.su-button {
	padding-inline: 12px !important;
	padding-block: 10px !important;
}

.mat-mdc-table td {
	color: #000000;
	font-size: 0.97rem;
}

.su-rounded {
	border-radius: 10px;
	padding: 4px !important;
	min-height: 35px !important;
	line-height: 1rem;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.su-lightly-rounded-corners {
	border-radius: 10px;
	padding: 4px !important;
	min-height: unset;
	line-height: 1rem;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.timepicker-overlay {
	z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
	z-index: 10000000 !important;
}

.trend-dialog .mat-mdc-dialog-container {
	overflow-y: hidden !important;
}

.modal-body {
	max-height: 60vh !important;
	min-height: 50vh !important;
	padding: 16px !important;
	overflow-y: auto !important;
}

.ws-nowrap {
	white-space: nowrap !important;
}

.bold {
	font-weight: bold;
}

.text-ellipsis {
	display: inline-block !important;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.rotate-90 {
	transform: rotate(90deg);
}

.centralize {
	display: flex !important;
	align-items: center;
	justify-content: center;
	gap: 2px;
}

a.icon-button {
	border-radius: 0 30px 30px 0;

	>span {
		display: flex;
		align-items: center;

		>span {
			flex-grow: 1;
			display: flex;
			padding-left: 10px;
			align-items: center;
		}
	}
}



.key-value {
	display: flex;
	align-items: center;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;

	.key {
		color: #777777;
	}

	.value {
		color: black;
		margin-left: 12px;
	}
}

.radius-0 {
	border-radius: 0;
}

.no-underline {
	text-decoration: none;
}

.timestamp {
	margin: 0 4px;
	border: none;
	font-family: arial, sans-serif;
	padding: 0 16px;
	cursor: pointer;
	text-align: center;
	line-height: 36px;
	font-weight: 500;
	font-size: 16px;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(43, 59, 93, 0.3);
	position: relative;
	color: black;
}

.timestamp:hover {
	background-color: #ebebeb !important;
}

.timestamp:active,
.timestamp.selected {
	background-color: #e9ecef !important;
	color: #40a9ff;
}

.sticky-bottom {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	z-index: 1020;
}

/* MATERIAL CUSTOM DESIGN */

.mat-vertical-stepper-content {
	visibility: visible !important;
	height: 100% !important;
}

.mat-vertical-content {
	padding: 16px 24px !important;
}

.mat-vertical-stepper-header {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.no-pointer .mat-vertical-stepper-header {
	pointer-events: none;
}

.dashboard .mat-mdc-text-field-wrapper {
	background: white !important;
	border: 1px solid #E5E7EB !important;
}

.dashboard .text-form-field-margin {
	padding-right: 8px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label.mat-tab-label-active {
	opacity: 1;
}

mat-tab-group.online-payment mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels {
	// justify-content: center !important;
	flex-wrap: nowrap;
}

mat-tab-group.online-payment mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab {
	max-width: 403px;
	min-width: 203px;
	box-shadow: 0px 0px 13.4px 0px #0000000D;
	background: #FFFFFFCC;
	border-radius: 2px;
	margin: 10px 10px;
}

mat-tab-group.online-payment mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
	font-size: 23px;
	color: #000000;
	font-weight: 500;
	line-height: 35px;
}

mat-tab-group.online-payment mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
	font-size: 23px;
	color: #6B7280;
	font-weight: 500;
	line-height: 35px;
}

// style for vipps payment configration

// style for terms page
mat-tab-group.terms mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels {
	justify-content: center !important;
	flex-wrap: nowrap;
}

mat-tab-group.terms mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab {
	max-width: 250px;
	min-width: 250px;
	box-shadow: 0px 0px 13.4px 0px #0000000D;
	background: #FFFFFFCC;
	border-radius: 2px;
	margin: 10px 10px;
}

mat-tab-group.terms mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__content .mdc-tab__text-label {
	font-size: 18px;
	color: #000000;
	font-weight: 500;
	line-height: 35px;
}

mat-tab-group.terms mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__content .mdc-tab__text-label {
	font-size: 18px;
	color: #6B7280;
	font-weight: 500;
	line-height: 35px;
}

// end of style for terms page



// end of style for vipps payment configration

.month-checkbox {
	width: 30%;
	padding: 8px;
}

.clickable-row {
	cursor: pointer;
}

.unpaid {
	text-transform: capitalize;
	background-color: #f5e8e8;
	border: 2px solid;
	border-color: #f5e8e8;
	color: #FA5C5C;
	border-radius: 2px;
	padding: 7px 12px;
	font-size: 15px;
}

.paid {
	text-transform: capitalize;
	background-color: #d9fff4;
	border: 2px solid;
	border-color: #d9fff4;
	color: #016c26;
	border-radius: 2px;
	padding: 7px 14px;
	font-size: 15px;
}

.pending {
	text-transform: capitalize;
	background-color: #f5e8e8;
	border: 2px solid;
	border-color: #f5e8e8;
	color: #016c26;
	border-radius: 2px;
	padding: 7px 12px;
	font-size: 15px;
}

//Material v16
.mdc-notched-outline__leading {
	border-top-left-radius: 2px !important;
	border-bottom-left-radius: 2px !important;
	border-color: #D1D5DB !important;
}

.mdc-notched-outline__trailing {
	border-top-right-radius: 2px !important;
	border-bottom-right-radius: 2px !important;
	border-color: #D1D5DB !important;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	border-color: var(--mdc-outlined-text-field-error-outline-color) !important;
}


.data-input .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	display: flex;
	padding-top: 10px;
	padding-bottom: 0px;
}



mat-form-field input::placeholder {
	font-weight: 500;
	color: #6B7280 !important;
}

.mat-mdc-select-placeholder {
	font-weight: 500;
	color: #6B7280 !important;
}

.mat-mdc-select-arrow {
	width: 14px;
	margin-top: 5px;
	background-image: url(/assets/select-arrow.svg);
	background-repeat: no-repeat;
	background-size: cover;
}

.mat-mdc-select-arrow svg {
	display: none;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
	// color: var(--mat-option-selected-state-label-text-color);
	color: rgb(0, 0, 0) !important;
}

.mat-mdc-text-field-wrapper {
	background: #F9FAFB !important;
}

.table-search-input mat-form-field .mat-mdc-text-field-wrapper {
	max-height: 40px !important;
}


.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-top: 10px;
	padding-bottom: 0px;
}

.mat-mdc-form-field-icon-prefix>.mat-icon {
	padding: 5px 0px 6px 5px !important;
	;
	box-sizing: content-box;
}

.mat-mdc-form-field-infix {
	min-height: 44px;
}

.calendar-view .cal-header {
	cursor: pointer;
}

.calendar-view .delete-btn {
	position: absolute;
	color: red;
	cursor: pointer;
}

.calendar-view .cal-week-view .cal-day-headers {
	position: sticky !important;
	top: 230px !important;
	z-index: 99 !important;
	background: #FFFFFF !important;
}

.calendar-view .cal-month-view .cal-header {
	position: sticky !important;
	top: 174px !important;
	z-index: 99 !important;
	background: #FFFFFF !important;
}

.cal-open-day-events {
	background-color: #fff !important;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
	padding: 15px;
	place-items: center;
}

mat-card {
	border: 1.5px solid var(--gray-200, #E5E7EB) !important;
}




// style for ck editor
ckeditor.ck-editor .ck-editor__top .ck-toolbar {
	background: #F9FAFB !important;
	border-radius: 8px 8px 0 0 !important;

}

ckeditor.ck-editor .ck-content {
	background: #F9FAFB !important;
	border-radius: 0 0 8px 8px !important;

}

mat-checkbox {

	&.mat-mdc-checkbox .mdc-label {
		font-size: 16px;
		font-weight: 500;
		line-height: 14px;
		color: #000000;
	}

	&.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
		background-color: var(--antd-wave-shadow-color);
	}

	&.mat-mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
		border-color: var(--mdc-checkbox-disabled-unselected-icon-color) !important;
		background-color: #a8a7a72b;
	}

	&.mat.mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
		opacity: 0;
	}
}

@media (max-width: 600px) {
	.login-form {
		width: 300px;
		min-width: 300px;
		max-width: 300px;
	}

	.label-font {
		font-size: 13px !important;
		font-weight: 600;
	}

	.calendar-view .cal-week-view .cal-day-headers {
		position: sticky !important;
		top: 230px !important;
		z-index: 99 !important;
		background: #FFFFFF !important;
	}

	.small-screen-btn {
		width: 50% !important;
	}

	.event-connection-alert .text-danger {
		font-size: 12px !important;
	}

	.save-button {
		width: 100% !important;
	}

	#apexTreeWrapper svg {
		height: 100% !important;
	}
	
	.filter-orders {
		mat-dialog-container {
			min-width: 400px;
			max-width: 400px;
		}
	}

	.calendar-agenda-view.mat-mdc-menu-panel {
		min-width: 350px !important;
		box-shadow: 0px 10px 15px -3px #0000001A !important;
		border-radius: 5px !important;
	}

	.event-detail-modal {
		mat-dialog-container {
			min-width: 400px !important;
		}
	}

}

@media (max-width: 320px) {
	.login-form {
		width: 230px;
		min-width: 230px;
		max-width: 230px;
	}
}

@media (max-width: 1024px) {
	.save-button {
		width: 100% !important;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.spin {
	transform-origin: center;
	animation: spin 1s linear infinite;
}

.spin-position {
	align-items: center;
	position: absolute;
	top: 30%;
	left: 45%;

}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
	border-color: #6cc76c !important;
}


/* Fancy Gradient Background and Text Effects */
.error-dialog-content {
	background: linear-gradient(135deg, #ff5f6d, #ffc371);
	/* Gradient red-orange background */
	color: white;
	padding: 24px;
	border-radius: 4px;
	text-align: center;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	/* Deep shadow for a pop-out effect */
	position: relative;
	/* Relative positioning for additional effects */
	overflow: hidden;
	/* Prevent overflow from animations */
}

.error-title {
	font-size: 28px;
	margin-bottom: 16px;
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
	/* Text shadow for depth */
}

.error-message {
	color: white;
	font-size: 18px;
	line-height: 1.5;
}

.error-dialog-actions {
	padding: 12px;
	display: flex;
	justify-content: flex-end;
}

/* Button Styles */
button[mat-raised-button] {
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 10px 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.2s, background-color 0.2s;
}

button[mat-raised-button]:hover {
	background-color: #b71c1c;
	/* Darker red on hover */
	transform: scale(1.05);
	/* Slight zoom effect on hover */
}

/* Fancy Blue Gradient Background and Icon */
.success-dialog-content {
	background: linear-gradient(135deg, #00c6ff, #0072ff);
	/* Blue gradient background */
	color: white;
	padding: 24px;
	border-radius: 4px;
	text-align: center;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	/* Deep shadow for a pop-out effect */
	position: relative;
	overflow: hidden;
}

/* Smiling Face Icon */
.success-icon {
	font-size: 25px;
	/* Bigger size for emphasis */
	animation: bounce 1s infinite;
	/* Bounce animation */
}

/* Title and Message */
.success-title {
	font-size: 28px;
	margin-bottom: 16px;
	font-weight: bold;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.success-message {
	font-size: 18px;
	line-height: 1.5;
	color: white;
}

/* Bounce Animation for Icon */
@keyframes bounce {

	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}
}

/* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

//Switch 
.switcher {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switcher input {
	opacity: 0;
	width: 0;
	height: 0;
}

.sliderer {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.sliderer:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.sliderer {
	background-color: #001c23;
}

input:focus+.sliderer {
	box-shadow: 0 0 1px #001c23;
}

input:checked+.sliderer:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

.sliderer.round {
	border-radius: 34px;
}

.sliderer.round:before {
	border-radius: 50%;
}

/* Step link styles */
.step-link {
	display: flex;
	align-items: center;
	font-weight: 500;
	transition: all 0.3s ease;
	position: relative;
}

/* Step icon styling */
.step-icon {
	width: 30px;
	height: 30px;
	background-color: #007bff;
	color: white;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 0.3s ease;
}

/* Active step */
.nav-link {
	color: #000;
	background-color: unset !important;
}

.nav-link.active .step-icon {
	background-color: unset !important;
	border: 2px solid #000;
}

/* Completed step (lighter green) */
.nav-link.completed .step-icon {
	background-color: #7f8285;
	/* Grey for completed steps */
	transform: scale(1.1);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

/* Disabled step */
.nav-link.disabled .step-icon {
	background-color: #ccc;
	cursor: not-allowed;
}

/* Hover effect for the step link */
.nav-link:hover {
	background-color: rgba(0, 123, 255, 0.1);
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

/* Active link text styling */
.nav-link.active {
	background-color: var(--su-color-primary) !important;
	font-weight: bold;
}

/* Disabled link text styling */
.nav-link.disabled {
	color: #ccc;
}

/* Adding progress bar under the steps */
.nav-pills {
	position: relative;
}

.nav-pills::after {
	content: "";
	position: absolute;
	height: 3px;
	background-color: #28a745;
	width: calc(100% * (currentStep / 4));
	/* Adjust width based on current step */
	bottom: -2px;
	left: 0;
	transition: width 0.3s ease;
}

/* Reusable Filter Styles */
.filters-container {
    display: grid;
    gap: 1rem;
    margin-bottom: 1.5rem;
    
    // Mobile first - one item per row
    grid-template-columns: 1fr;
    
    // Medium screens - two items per row
    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    
    // Large screens - all items in one row
    @media (min-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .filter-item {
        width: 100%;
        
        &.full-width {
            @media (min-width: 768px) {
                grid-column: 1 / -1;
            }
        }
        
        // Ensure all form fields take full width
        ::ng-deep {
            .mat-form-field,
            app-input,
            app-select,
            app-date-picker-input,
            app-clock-time-picker {
                display: block;
                width: 100%;
            }
        }
    }
}

mat-label {
	margin: 0;
}

.grey-box {    
	background: #f9fafb;
    padding: 10px 4px;
    border-radius: 2px;
    border: #d1d5dbf0 solid 1px;
}

.white-box {
	border-radius: 2px;
	background-color: #ffffff;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}


.floating-button {
	position: absolute;
	padding: 5px !important;
	top: -10px;
	right: -15px;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
    height: 35px;
    min-height: unset;
}
  

.floating-button:hover {
	background-color: #f0f0f0;
}
  
.cursor-pointer {
    cursor: pointer !important;
}

.navigation-button-min-width {
	min-width: 174px !important;
}

.mat-step-text-label {
	font-size: 1.08rem;
    font-weight: bold;
}

.mat-step-icon-selected, .mat-step-icon-state-edit {
	background-color: #032d49 !important;
}

.font-dosis {
	font-family: 'Dosis', sans-serif !important;
}